const Config = [];
// for the production
Config['API_URL']=`https://buzzway-api.nshops.in:3001`;
Config['API_ACCESS_TOKEN']=`Bearer eyJhbGciOiJIUzI1NiJ9.eyJVc2VyIjoiQXV0aGVudGljYXRlZCIsIl9pZCI6IlNvbWVUaW5nSXNOZXciLCJBY2Nlc3MiOiJidXp6d2F5QWNjZXNzS2V5In0.ZtRkKesgXDHlWNTKMHhlsDFHeQhwSWSnvGa62b64rpQ`
Config['IMG']=`https://buzzway-api.nshops.in:3001/public/uploads/`;
Config['ROZAR_PAY_KEY']='rzp_test_nvsKQrmWNp3a5q';
Config['BASE_URL'] = 'https://buzzway-api.nshops.in'

// /** for the development */
// Config['API_URL']=`http://localhost:3001`;
// Config['API_ACCESS_TOKEN']=`Bearer eyJhbGciOiJIUzI1NiJ9.eyJVc2VyIjoiQXV0aGVudGljYXRlZCIsIl9pZCI6IlNvbWVUaW5nSXNOZXciLCJBY2Nlc3MiOiJidXp6d2F5QWNjZXNzS2V5In0.ZtRkKesgXDHlWNTKMHhlsDFHeQhwSWSnvGa62b64rpQ`
// Config['IMG']=`http://localhost:3001/public/uploads/`;
// Config['ROZAR_PAY_KEY']='rzp_test_nvsKQrmWNp3a5q';
// Config['BASE_URL'] = 'http://localhost:3000'

export default Config;